<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader title="借出资产"></modalHeader>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="formModal" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<a-form-item name="unitId" label="借入单位" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-select v-model:value="formModal.unitId" placeholder="请选择借入单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item name="assetsId" label="资产名称" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<assetsSelect v-model:value="formModal.assetsId" @change="onAssetsChange" placeholder="请选择借出资产">
						<!-- <assetsSelect v-model:value="assetsIds" @change="onAssetsChange" placeholder="请选择盘亏资产">	 -->
							<template #value>
								{{ assetsData.name }}
							</template>
						</assetsSelect>
						<!-- <a-select
							v-model:value="formModal.assetsId"
							show-search
							placeholder="请输入资产名称"
							:default-active-first-option="false"
							:show-arrow="false"
							:filter-option="false"
							:not-found-content="null"
							:options="assetsData"
							:field-names="{value:'id',label:'name',options: 'assetsData'}"
							@search="handleSearch"
							@change="handleChange"
						></a-select> -->
					</a-form-item>
					<a-form-item  name="returnTime" label="归还日期" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-date-picker v-model:value="formModal.returnTime" showTime></a-date-picker>
					</a-form-item>
					<a-form-item name="fileList" label="出借凭证" class="ui-form__item" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<filesUpload v-model:value="formModal.fileList"></filesUpload>
						<!-- <p>注：建议上传pdf格式的文件，大小不超过100M</p> -->
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { applyBorrowOrLend, getAssetsDetailSearch } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import assetsSelect from '@/components/assetsSelect/index.vue';
	export default {
		components: { Icon, filesUpload, modalHeader, assetsSelect },
		data() {
			return {
				loading: false,
				visible: false,
				assetsData: {},
				// assetsIds: [],
				formModal: {
					fileList: [],
					// fromUnitId: null
				},
				timer: null, // 定时器
				// tempDate: 0,
				fileList: []
			}
		},
		methods: {
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAssetsDetailSearch({
						nameOrCode: item,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.assetsData = ret.data
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.formModal));
					let tempIds = postData.assetsId
					postData.assetsId = tempIds[0]
					let temp = this.transDateTime(postData.returnTime)
					let tempTime = new Date(temp)
					postData.returnTime = tempTime.valueOf()
					postData.returnTime = this.moment(postData.returnTime).unix();
					postData.materialUrl = this.formModal.fileList.toLocaleString()
					this.loading = true;
					try {
						let ret = await applyBorrowOrLend(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('借出成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.modelRef = {};
						this.loading = false;
					}
				})
			},
			open() {
				// console.log("lend")
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
				this.formModal = {
					fileList: []
				}
			},
			onAssetsChange(assetsData) {
				if(assetsData.length !== 0) {
					this.getData(assetsData[0].code).then(()=>{
						this.$refs.formRef.clearValidate();
					});
				}else{
					this.assetsData = {}
				}
				// this.getData(assetsData[0].code);
			}
		},
	}
</script>

<style scoped>
	
</style>